import { gql } from '@apollo/client';

// Authentication
export const USER_LOGIN = gql`
  query UserLogin($username: String!, $password: String!) {
    userLogin(userName: $username, password: $password)
  }
`;

// Helper function to create dynamic mutation
export const createAddUserMutation = (fields: Record<string, any>) => {
  const variables = Object.keys(fields)
    .map(key => `$${key}: ${getGraphQLType(key)}`)
    .join(', ');

  const userFields = Object.keys(fields)
    .map(key => `${key}: $${key}`)
    .join(', ');

  return gql`
    mutation AddUser(${variables}) {
      addUser(user: { ${userFields} })
    }
  `;
};

// Helper to determine GraphQL types
const getGraphQLType = (field: string): string => {
  const requiredFields = {
    userName: 'String!',
    firstName: 'String!',
    lastName: 'String!',
    passwordHash: 'String!',
    emailAddress: 'String!',
    isPrivate: 'Boolean!'
  };

  const optionalFields = {
    countryCode: 'Short',
    phoneNumber: 'Long',
    address: 'String',
    city: 'String',
    stateId: 'UUID',
    countryId: 'UUID',
    zip: 'Int'
  };

  return requiredFields[field] || optionalFields[field] || 'String';
};

// User Management
export const CREATE_USER = createAddUserMutation({
  userName: 'String!',
  firstName: 'String!',
  lastName: 'String!',
  passwordHash: 'String!',
  emailAddress: 'String!',
  countryCode: 'Short',
  phoneNumber: 'Long',
  address: 'String',
  city: 'String',
  stateId: 'UUID',
  countryId: 'UUID',
  zip: 'Int',
  isPrivate: 'Boolean!'
});

// Reference Data
export const GET_COUNTRY_LIST = gql`
  query GetCountryList {
    countries {
      countryId
      countryName
    }
  }
`;

export const GET_STATE_LIST = gql`
  query GetStateList {
    states {
      stateId
      stateName
      countryId
    }
  }
`;

export const GET_USER = gql`
  query GetUser($userId: ID!) {
    user(userId: $userId) {
      userId
      userName
      firstName
      lastName
      emailAddress
      countryCode
      phoneNumber
      address
      city
      state
      country
      zip
      isPrivate
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $userId: ID!,
    $userName: String,
    $firstName: String,
    $lastName: String,
    $emailAddress: String,
    $countryCode: String,
    $phoneNumber: String,
    $address: String,
    $city: String,
    $state: String,
    $country: String,
    $zip: String,
    $isPrivate: Boolean
  ) {
    updateUser(
      userId: $userId,
      userName: $userName,
      firstName: $firstName,
      lastName: $lastName,
      emailAddress: $emailAddress,
      countryCode: $countryCode,
      phoneNumber: $phoneNumber,
      address: $address,
      city: $city,
      state: $state,
      country: $country,
      zip: $zip,
      isPrivate: $isPrivate
    ) {
      userId
      userName
      firstName
      lastName
      emailAddress
      countryCode
      phoneNumber
      address
      city
      state
      country
      zip
      isPrivate
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($userId: ID!) {
    deleteUser(userId: $userId)
  }
`;

// Acquaintance Management
export const GET_ACQUAINTANCES = gql`
  query GetAcquaintances {
    acquaintances {
      acquaintanceId
      userId
      acquaintanceUserId
      acquaintanceType
      acquaintanceUser {
        userId
        userName
        firstName
        lastName
      }
    }
  }
`;

export const ADD_ACQUAINTANCE = gql`
  mutation AddAcquaintance($acquaintanceUserId: Int!, $acquaintanceType: String!) {
    addAcquaintance(
      acquaintanceUserId: $acquaintanceUserId,
      acquaintanceType: $acquaintanceType
    ) {
      acquaintanceId
      userId
      acquaintanceUserId
      acquaintanceType
      acquaintanceUser {
        userId
        userName
        firstName
        lastName
      }
    }
  }
`;

export const UPDATE_ACQUAINTANCE = gql`
  mutation UpdateAcquaintance($acquaintanceId: Int!, $acquaintanceType: String!) {
    updateAcquaintance(
      acquaintanceId: $acquaintanceId,
      acquaintanceType: $acquaintanceType
    ) {
      acquaintanceId
      userId
      acquaintanceUserId
      acquaintanceType
      acquaintanceUser {
        userId
        userName
        firstName
        lastName
      }
    }
  }
`;

export const DELETE_ACQUAINTANCE = gql`
  mutation DeleteAcquaintance($acquaintanceId: Int!) {
    deleteAcquaintance(acquaintanceId: $acquaintanceId)
  }
`;

export const SEARCH_USERS_BY_NAME = gql`
  query SearchUsersByName($firstName: String!, $lastName: String!) {
    searchUsersByName(firstName: $firstName, lastName: $lastName) {
      userId
      userName
      firstName
      lastName
    }
  }
`;

export const SEARCH_USERS_BY_USERNAME = gql`
  query SearchUsersByUsername($userName: String!) {
    searchUsersByUsername(userName: $userName) {
      userId
      userName
      firstName
      lastName
    }
  }
`;