import React from 'react';
import yggdrasil from '../assets/img/Yggdrasil_header.jpg';
import '../styles/Header.css';
import { jwtDecode } from "jwt-decode";

interface DecodedToken {
  username: string;
  exp: number;
}

const Header: React.FC = () => {
  const token = sessionStorage.getItem('token');
  let username = '';
  
  if (token) {
    const decoded = jwtDecode<DecodedToken>(token);
    username = decoded.username;
  }

  const handleLogout = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    sessionStorage.removeItem('token');
    window.location.reload();
  };

  return (
    <header className="header">
      <div className="header-container">
        <img src={yggdrasil} alt="Yggdrasil" />
      </div>
      <div className="header-text">
        <h1>Yggdrasil</h1>
        <p>a table top RPG management tool</p>
      </div>
      <div className="user-info">
        {username && <p>{username}</p>}
      </div>
      <div className="navigation">
        {username ? (
          <>
            <a href="/guides" className="nav-link">Guides</a>
            <a href="/quick-create" className="nav-link">Quick Create</a>
            <a href="/gallery" className="nav-link">Public Gallery</a>
            <a href="/message-board" className="nav-link">Message Board/Reddit</a>
            <a href="/social" className="nav-link">Social Media Pages</a>
            <a href="/acquaintances" className="nav-link">Acquaintances</a>
            <a href="#" className="btn-auth" onClick={handleLogout}>Logout</a>
          </>
        ) : (
          <div className="auth-links">
            <a href="/login" className="nav-link">Login</a>
            <a href="/user" className="nav-link">Create Account</a>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header; 