import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/User.css';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CREATE_USER, GET_COUNTRY_LIST, GET_STATE_LIST, createAddUserMutation } from '../graphql/users.ts';

interface UserFormData {
  userName: string;
  firstName: string;
  lastName: string;
  password: string;
  emailAddress: string;
  countryCode: string;
  phoneNumber: string;
  address: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  isPrivate: boolean;
}

const User: React.FC = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<UserFormData>({
    userName: '',
    firstName: '',
    lastName: '',
    password: '',
    emailAddress: '',
    countryCode: '+1',
    phoneNumber: '',
    address: '',
    city: '',
    state: '',
    country: '',
    zip: '',
    isPrivate: false
  });
  const [usernameError, setUsernameError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');

  const countryCodes = ['+1', '+44', '+49', '+33', '+81'];
  const [getCountries, { data: countryData }] = useLazyQuery(GET_COUNTRY_LIST);
  const [getStates, { data: stateData }] = useLazyQuery(GET_STATE_LIST);
  const [createUser] = useMutation(CREATE_USER);

  // Load countries on component mount
  React.useEffect(() => {
    getCountries();
  }, [getCountries]);

  // Load states when country changes
  React.useEffect(() => {
    if (formData.country) {
      getStates();
    }
  }, [formData.country, getStates]);

  const validateUsername = (value: string) => {
    if (value.length < 4 || value.length > 40) {
      setUsernameError('Username must be between 4 and 40 characters');
      return false;
    }
    setUsernameError('');
    return true;
  };

  const validatePassword = (value: string) => {
    if (value.length < 10 || value.length > 40) {
      setPasswordError('Password must be between 10 and 40 characters');
      return false;
    }
    setPasswordError('');
    return true;
  };

  const validateRequiredFields = () => {
    let isValid = true;

    if (!formData.userName.trim()) {
      setUsernameError('Username is required');
      isValid = false;
    }

    if (!formData.password.trim()) {
      setPasswordError('Password is required');
      isValid = false;
    }

    if (!formData.emailAddress.trim()) {
      setEmailError('Email address is required');
      isValid = false;
    }

    if (!formData.firstName.trim()) {
      setFirstNameError('First name is required');
      isValid = false;
    }

    if (!formData.lastName.trim()) {
      setLastNameError('Last name is required');
      isValid = false;
    }

    return isValid;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    
    // Special handling for country and state selection
    if (name === 'country') {
      setFormData(prev => ({
        ...prev,
        state: '',  // Clear state when country changes
        country: value  // Keep as string for UUID
      }));
      return;
    }

    if (name === 'state') {
      setFormData(prev => ({
        ...prev,
        state: value  // Keep as string for UUID
      }));
      return;
    }

    const newValue = type === 'checkbox' ? (e.target as HTMLInputElement).checked : value;
    setFormData(prev => ({ ...prev, [name]: newValue }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateRequiredFields() || 
        !validateUsername(formData.userName) || 
        !validatePassword(formData.password)) {
      return;
    }

    // Build base user object with required fields
    const userInput: Record<string, any> = {
      userName: formData.userName.trim(),
      firstName: formData.firstName.trim(),
      lastName: formData.lastName.trim(),
      passwordHash: formData.password,
      emailAddress: formData.emailAddress.trim(),
      isPrivate: Boolean(formData.isPrivate)
    };

    // Add optional fields if they have valid values
    const optionalFields = {
      countryCode: formData.countryCode ? parseInt(formData.countryCode.replace('+', '')) : null,
      phoneNumber: formData.phoneNumber ? parseInt(formData.phoneNumber.replace(/\D/g, '')) : null,
      address: formData.address?.trim(),
      city: formData.city?.trim(),
      stateId: formData.state || null,
      countryId: formData.country || null,
      zip: formData.zip ? parseInt(formData.zip.replace(/\D/g, '')) : null
    };

    // Add non-null optional fields to userInput
    Object.entries(optionalFields).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== '') {
        userInput[key] = value;
      }
    });

    try {
      const mutation = createAddUserMutation(userInput);
      const result = await createUser({ 
        mutation,
        variables: userInput
      });
      if (result.data?.addUser) {
        sessionStorage.setItem('token', result.data.addUser);
        navigate('/');
        window.location.reload();
      }
    } catch (error) {
      console.error('Error creating user:', error);
    }
  };

  return (
    <div className="user-container">
      <form className="user-form" onSubmit={handleSubmit}>
        <h2>User Information</h2>
        <p className="required-note">* Required fields</p>
        
        <div className="form-group">
          <label>Username *</label>
          <div className="input-wrapper">
            <input
              type="text"
              name="userName"
              placeholder="Username"
              value={formData.userName}
              onChange={handleChange}
              required
              title="Username must be between 4 and 40 characters"
              className={usernameError ? 'error' : ''}
            />
            <span className="tooltip">Username must be between 4 and 40 characters</span>
            {usernameError && <span className="error-message">{usernameError}</span>}
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>First Name *</label>
            <div className="input-wrapper">
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                value={formData.firstName}
                onChange={handleChange}
                required
                className={firstNameError ? 'error' : ''}
              />
              {firstNameError && <span className="error-message">{firstNameError}</span>}
            </div>
          </div>
          <div className="form-group">
            <label>Last Name *</label>
            <div className="input-wrapper">
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={handleChange}
                required
                className={lastNameError ? 'error' : ''}
              />
              {lastNameError && <span className="error-message">{lastNameError}</span>}
            </div>
          </div>
        </div>

        <div className="form-group">
          <label>Password *</label>
          <div className="input-wrapper">
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
              required
              title="Password must be between 10 and 40 characters"
              className={passwordError ? 'error' : ''}
            />
            <span className="tooltip">Password must be between 10 and 40 characters</span>
            {passwordError && <span className="error-message">{passwordError}</span>}
          </div>
        </div>

        <div className="form-group">
          <label>Email Address *</label>
          <div className="input-wrapper">
            <input
              type="email"
              name="emailAddress"
              placeholder="Email Address"
              value={formData.emailAddress}
              onChange={handleChange}
              required
              className={emailError ? 'error' : ''}
            />
            {emailError && <span className="error-message">{emailError}</span>}
          </div>
        </div>

        <div className="form-row">
          <div className="form-group select-small">
            <select
              name="countryCode"
              value={formData.countryCode}
              onChange={handleChange}
            >
              {countryCodes.map(code => (
                <option key={code} value={code}>{code}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <input
              type="tel"
              name="phoneNumber"
              placeholder="Phone Number"
              value={formData.phoneNumber}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="form-group">
          <input
            type="text"
            name="address"
            placeholder="Address"
            value={formData.address}
            onChange={handleChange}
          />
        </div>

        <div className="form-row">
          <div className="form-group">
            <input
              type="text"
              name="city"
              placeholder="City"
              value={formData.city}
              onChange={handleChange}
            />
          </div>
          <div className="form-group select-medium">
            <select
              name="state"
              value={formData.state || ''}
              onChange={handleChange}
            >
              <option value="">Select State</option>
              {stateData?.states?.map(state => (
                <option key={state.stateId} value={state.stateId}>
                  {state.stateName}
                </option>
              )) || null}
            </select>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <select
              name="country"
              value={formData.country || ''}
              onChange={handleChange}
            >
              <option value="">Please select country</option>
              {countryData?.countries?.map(country => (
                <option key={country.countryId} value={country.countryId}>
                  {country.countryName}
                </option>
              )) || null}
            </select>
          </div>
          <div className="form-group">
            <input
              type="text"
              name="zip"
              placeholder="ZIP Code"
              value={formData.zip}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="form-group checkbox">
          <label>
            <input
              type="checkbox"
              name="isPrivate"
              checked={formData.isPrivate}
              onChange={handleChange}
            />
            Private Profile
          </label>
        </div>

        <div className="button-group">
          <button type="submit" className="btn-auth">Submit</button>
          <button 
            type="button" 
            className="btn-auth btn-cancel"
            onClick={() => navigate('/')}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default User;
