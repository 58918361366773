// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
  position: fixed;
  left: 0;
  top: 138px;
  width: 200px;
  height: calc(100vh - 138px);
  background-color: #002440;
  padding: 1rem 0;
}

.nav-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav-item {
  padding: 0.5rem 0;
}

.nav-item a {
  color: #FFD700;
  text-decoration: none;
  font-family: 'Cinzel Decorative', cursive;
  display: block;
  padding: 0.8rem 1rem;
  border: 1px solid #FFD700;
  border-width: 0 0 1px 0;
  border-radius: 0 0 10px 10px;
  margin-bottom: 0.5rem;
  transition: all 0.3s ease;
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.7);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.3);
}

.nav-item a:hover {
  background-color: rgba(255, 215, 0, 0.1);
  transform: translateX(10px);
  box-shadow: 0 6px 8px -1px rgba(0, 0, 0, 0.5);
} `, "",{"version":3,"sources":["webpack://./src/styles/Navbar.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,OAAO;EACP,UAAU;EACV,YAAY;EACZ,2BAA2B;EAC3B,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,UAAU;EACV,SAAS;AACX;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,qBAAqB;EACrB,yCAAyC;EACzC,cAAc;EACd,oBAAoB;EACpB,yBAAyB;EACzB,uBAAuB;EACvB,4BAA4B;EAC5B,qBAAqB;EACrB,yBAAyB;EACzB,2CAA2C;EAC3C,6CAA6C;AAC/C;;AAEA;EACE,wCAAwC;EACxC,2BAA2B;EAC3B,6CAA6C;AAC/C","sourcesContent":[".navbar {\n  position: fixed;\n  left: 0;\n  top: 138px;\n  width: 200px;\n  height: calc(100vh - 138px);\n  background-color: #002440;\n  padding: 1rem 0;\n}\n\n.nav-list {\n  list-style: none;\n  padding: 0;\n  margin: 0;\n}\n\n.nav-item {\n  padding: 0.5rem 0;\n}\n\n.nav-item a {\n  color: #FFD700;\n  text-decoration: none;\n  font-family: 'Cinzel Decorative', cursive;\n  display: block;\n  padding: 0.8rem 1rem;\n  border: 1px solid #FFD700;\n  border-width: 0 0 1px 0;\n  border-radius: 0 0 10px 10px;\n  margin-bottom: 0.5rem;\n  transition: all 0.3s ease;\n  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.7);\n  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.3);\n}\n\n.nav-item a:hover {\n  background-color: rgba(255, 215, 0, 0.1);\n  transform: translateX(10px);\n  box-shadow: 0 6px 8px -1px rgba(0, 0, 0, 0.5);\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
